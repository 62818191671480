#contact-us{
    padding: 100px;
    padding-top: 200px;
    width: 100vw;
    height: auto;
}
@media only screen and (max-width: 780px){
    #contact-us{
        padding: 200px 50px;
    }
}
@media only screen and (max-width: 640px){
    #contact-us{
        padding: 80px 10px;
    }
}