@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Montserrat+Alternates:wght@600&display=swap');

:root {
  --black: #292929;
  --orange: #E4692D;
  --blue: #01225E;
}
body{
  overflow-x: hidden !important;
}

h1, p, subtitle, h2, button, smalltxt, h3{
   color: var(--black);
   display: block;
}
h3{
  font-size: 33px !important;
  font-family: 'Montserrat', sans-serif;
}
h2{ 
  font-size: 23px !important;
  font-family: 'Montserrat Alternates', sans-serif;
}
h1, p, subtitle, button, a, smalltxt, .ff-secfields, ul{
  font-family: 'Montserrat', sans-serif;
}
smalltxt{
  color: black;
  font-size: 20px;
}
a{
  color: var(--black) !important;
  text-decoration: none !important;
}
a.navLink{
  color: var(--black) !important;
  text-decoration: none !important;
  position: relative;
}

a.navLink:not(.logo)::before{
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease-in-out;
}

a.navLink:hover::before {
  width: 100%;
}

h1{
   font-size: 50px;
}
subtitle{
  font-size: 42px;
}
p, button, a, ul{
  font-size: 25px !important;
}
#ff-submit-root, #ff-submit{
  color: white !important;
  border: solid var(--black) 2px !important;
  background: var(--black) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  padding: 5px 30px !important;
  transition: ease-in-out .2s;
}
button, #ff-submit-root, #ff-submit{
  color: white;
  border: solid var(--black) 2px;
  background: var(--black);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 30px;
  transition: ease-in-out .2s;
}
button:hover, #ff-submit-root:hover{
  color: var(--black);
  background-color: white;
}

.orange-btn{
  color: white;
  border: solid var(--orange) 2px !important;
  background-color: var(--orange);
  border: var(--orange);
  transition: ease-in-out .3s;
}
.orange-btn:hover{
  color: var(--orange);
  background-color: white;
  border: solid var(--orange) 2px;
}
.blue-btn{
  color: white;
  border: solid var(--blue) 2px !important;
  background-color: var(--blue);
  border: var(--blue);
  transition: ease-in-out .3s;
}
.blue-btn:hover{
  color: var(--blue);
  background-color: white;
  border: solid var(--blue) 2px;
}
.white-btn{
  color: var(--black);
  background-color: white;
}
.white-btn:hover{
  color: white;
  background-color: var(--black);
}
.blue-txt{
  color: var(--blue);
}
.orange-txt{
  color: var(--orange);
}
.white-txt{
  color: white;
}

.box-shadow{
  height: auto;
  width: auto;
  padding: 50px 20px;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
}
.shadow{
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
}
#Publish1FAIpQLSeyF2oO7IEb3Os34ooUGzBtUX6e4h9RGqnla_Mo3NgDGDhHTw{
  font-family: 'Montserrat', sans-serif;
  background: transparent;
}

#form, #contact-us{
   display: flex;
   align-items: center;
   background: url('./Assets/Images/Contact_Abstract.png');
   background-size: cover;
   background-position: center;
   background-attachment: fixed;
   height: 100vh;
}

#ff-title-root{
  color: var(--orange);
  font-size: 23px !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
  margin-bottom: -10px;
}
#ff-desc-root > p{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 50px !important;

}
#ff-id-615548919{
  width: 50%;
  display: inline-block;
  
}

#ff-id-653772181{
  width: 45%;
  display: inline-block;
  margin-left: 20px;
}

#ff-submit-root > img{
  display: none;
}
.ff-qs-html-text, #ff-id-564226097 > label, #ff-id-653772181 > label, #ff-id-615548919 > label, #ff-submit-root > span{
  font-size: 20px;
}
@media only screen and (max-width: 1078px){
  #ff-id-615548919{
    width: 100%;
    display: block;
    
  }
  #ff-id-653772181{
    width: 100%;
    display: block;
    margin-left: 0px;
  }

}
@media only screen and (max-width: 744px){
   p{
    font-size: 20px !important;
  }
  #services > div.breadcrumbs > p > span > a, #project > div.project-body > p > span > a{
    font-size: 20px !important;

  }
}
@media only screen and (max-width: 461px){
  h1,#ff-desc-root > p{
    font-size: 30px !important;
  }
  h2{
    font-size: 18px !important;
  }
}

#footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--black);
  padding: 10px;
}
#footer > a{
  color: white !important;
  font-size: 17px !important;
}
#privacy, #terms{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.77) 0%, rgba(255, 255, 255, 0.77) 100%),  url('./Assets/Images/projectBody.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 100px;
}
@media only screen and (max-width: 633px){
  #footer{
    padding-bottom: 100px !important;
  }
}
.facebook {
  margin: auto;
  margin-top: 15px;
}
.facebook > a{
  
  color: white !important;
}
.facebook > a:hover{
  color: var(--black) !important;
}
