.logo-btn{
    position: absolute;
    top: 0;
    width: 100px;
}

#landing-navigation, #other-nav {
    position: fixed !important;
    width: 100%;

}
#landing-navigation{
    margin-top: 20px;
}
.bar, #other-nav {
    padding: 10px 100px 10px 30px;
    background: rgba(44, 44, 44, 0.83);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#other-nav {
    padding: 0px;
}
.bar{
    top: 10px;
    float: right;
    height: 100%;
}
.bar > a, #other-nav > .first > a{
    margin-right: 15px;
    color: white !important;
}
.logo > img{
    transition: ease-in-out .2s;
}
.logo > img:hover{
  transform: scale(1.05);
}
#mobile-nav{
    position: fixed;
    z-index: 999;
    display: none;
}
.menu.mobile {
    background-color: var(--black);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
   
  }
.navLink.mobile{
    font-size: 30px !important;
    background-color: var(--black);
    display: block;
    padding: 10px;
    color: white !important;
}
/* nav.css */

.hamburger {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
    cursor: pointer;
  }

  .close-button {
    color: white;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    cursor: pointer;
  }
.white-btn.call-cta > a{
    color: var(--black) !important;
}
.white-btn.call-cta > a:hover{
    color: white !important;
}
@media only screen and (max-width: 640px){
    #landing-navigation,  #other-nav, .link-logo{
        display: none;
    }
    #mobile-nav{
        display: block;
    }
}
.call-cta-mobile{
 
}