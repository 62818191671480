#landing > section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    background: url('../../Assets/Images/Landing.png');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;}
#landing > section > div{
    height: 500px;
}
#landing > section > button{
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  
}

#landing > section > div.info{
    padding: 50px 20px;
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  
    display: flex;
    align-items: center;
    translate: -50px;
}
#landing > section > div.info > div{
    border-left: solid var(--black) 3px;
    padding: 25px;
}
#landing > section > div.img{
    translate: 0px 50px;
}
@media only screen and (max-width: 1481px) {
    #landing > section {
      display: flex;
      justify-content: space-around;
    }
    #landing > section > div.info {
      width: 60%;
    }
    #landing > section > div.img {
      transform: translate(0px, -50px);
    }
    #landing > section > div.img > p {
      display: none;
    }
    #landing > section > div.img > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
@media only screen and (max-width: 991px){
    #landing > section{
        justify-content: center;
    }
    #landing > section > div.info{
        width: 80%;
        height: auto;
    }
    #landing > section > div.img {
       display: none;
    }
}
@media only screen and (max-width: 497px){
  #landing > section > div.info{
    background: transparent;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0);  
}
#landing > section > div.info{
  width: 100%;
  margin-left: 20px;

}

}
/*Project*/
#project-section > section{
  height: 100vh;
  background-image: url('../../Assets/Marquez\ House.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.get-things-done{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: white;
  height: 100%;
}
.get-things-done > div{
  padding: 25px;
  border-left: solid var(--black) 3px;
}
.link-logo > img{
  top: 10px;
  left: 10px;
  z-index: 999;
  position: absolute;
  transition: ease-in-out .2s;
}
.link-logo > img:hover{
  transform: scale(1.05);
}
/*Reviews*/
#reviews{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  color: white;
  background: var(--black);
}

@media only screen and (max-width: 767px){
  #reviews{
    padding: 100px 0px;
  }
  #project-section > section{
    background-image: url('../../Assets/Images/Abstract_Building.png');
    background-color: var(--black);
  }
  
}
@media only screen and (max-width: 502px){
  #reviews > section > p{
    font-size: 20px !important;
  }
  .arrow-button{
    padding: 5px;
  }
}
/*What We Do*/
#what-we-do{
   padding: 100px;
   height: 135vh;
}
#what-we-do > section > h1{
  text-align: center;
}
.service-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-container > .container{
   text-align: center;
   margin-bottom: 10px;
}
.service-container > .container > h2{
  margin-bottom: 10px;
}
.residential > .icon, .contracting > .icon, .commercial > .icon{
  margin: auto;
  height: 250px;
  width: 250px;
}
.residential > .icon{
  background: url('../../Assets/Icon/residential.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.contracting > .icon{
  background: url('../../Assets/Icon/contract.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.commercial > .icon{
  background: url('../../Assets/Icon/commercial.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.services-cta{
  margin: auto;

}

@media only screen and (max-width: 991px){
  #ff-compose{
    background: rgba(255, 255, 255, 0.80);
    height: 100%;
    padding: 40px 10px;

  }

}
@media only screen and (max-width: 1307px){
  .service-container{
    display: block;
  }
  #what-we-do{
    padding: 50px 0px;
    height: auto;
 }
 .service-container > .container{
    width: 90%;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .residential > .icon, .contracting > .icon, .commercial > .icon{
    width: 40%;
  }
}
@media only screen and (max-width: 991px){
  .service-container > .container{
    margin-top: 50px;
    display: block;
  }
  .service-container > .container > .txt > h2, .service-container > .container > .txt > smalltxt{
    text-align: center;
  }
  .residential > .icon, .contracting > .icon, .commercial > .icon{
    margin: auto;
    width: 100px;
    height: 100px;
  }

}
.call-cta > a, .call-cta-mobile > a{
  color: white !important;
}
.call-cta > a:hover, .call-cta-mobile > a:hover{
  color: var(--blue) !important;
}
#project > div.project-body > div:nth-child(3) > div:nth-child(2) > div > h1{
  text-align: center !important;
}
@media only screen and (max-width: 640px){
  .call-cta:not(.mobile){
    display: none;
  }
}