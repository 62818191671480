.project-body{
    background: url('../../Assets/Images/projectBody.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 100px;
}
.project-banner{
    background: url('../../Assets/Images/Project.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 200px 80px;
}
@media only screen and (max-width:488px){
    .project-banner{
        padding: 100px 20px;
        background: url('../../Assets/Images/Project.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: static;
    }
}

.arrow-button{
    box-shadow: none;
}
.arrow-button {
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10;
    margin: 10px;
    color: inherit;
  }
  
  .arrow-button:hover {
    color: white;
    background: transparent;
  }
  
.project-banner > div > a{
    margin-top: 20px; 
}
.facebook-block{
    width: 100%;
    text-align: center;
    padding: 100px 50px;
}
#project-gallery{
    display: inline-block;
    background: rgba(255, 255, 255, 0.59);
    width: 47%;
    border: solid var(--black);
}
.project-row{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.project-card{
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
}
.project-card > div{
    width: 90%;
}
.project-card > div > img{
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
}
.project-card::-webkit-scrollbar {
    width: 6px; 
}
  
.project-card::-webkit-scrollbar-track {
    background-color: #F4F4F4; 
}
  
.project-card::-webkit-scrollbar-thumb {
    background-color: var(--black) ; /* Change the thumb color */
    border-radius: 3px;
}

.bottom-title, .top-title{
    padding: 26px 15px;
    background-color: var(--black);
}
.bottom-title > .title, .top-title > .title {
    font-size: 30px !important;
}
.bottom-title > p, .top-title > p{
    color: white;
}
.top-title{
    display: none;
}
.scroll-in-box{
    display: none;
}
@media only screen and (max-width: 785px) {
    .scroll-in-box{
        color: white;
        background-color: var(--black);
        padding: 10px;
        z-index: 2;
        font-weight: bold;
        top: 5px;
        right: 0px;
        position: absolute;
        display: block;
    }
    .top-title{
        display: block;
    }
    .bottom-title{
        display: none;
    }
    .project-body{
        padding: 10px !important;
    }
    .bottom-title > .title, .top-title > .title {
        font-size: 25px !important;
    }
    .project-card{
        padding: 0px;
    }
}
@media only screen and (max-width: 1256px) {
    .project-row{
        display: block;
        margin-bottom: 0px;
    }
    #project-gallery{
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }
    .project-body{
        padding: 50px;
    }
    
}